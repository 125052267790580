import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { canUseDom } from '../../utils';

export default function ScrollToTop(): null {
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => canUseDom && window.scrollTo(0, 0));
  }, []);

  return null;
}
