import React from 'react';
import i18next from 'i18next';
import parser from 'accept-language-parser';
import { renderRoutes } from 'react-router-config';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { ChakraProvider, ColorModeScript, createCookieStorageManager } from '@chakra-ui/react';
import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/react';

import theme from './styles/theme';
import { global } from './styles/global';
import ScrollToTop from './components/ScrollToTop';
import { canUseDom } from './utils';
import { routes } from './router';

import ro from './locales/ro/translation.json';
import ru from './locales/ru/translation.json';
import en from './locales/en/translation.json';

function App({ location, helmetContext, routerContext, client }: any) {
  const Router: any = canUseDom ? BrowserRouter : StaticRouter;
  const manager = createCookieStorageManager('news24md');

  const i18n = i18next.use(initReactI18next).init({
    lng: parser.pick(['ro', 'ru', 'en'], location, { loose: true }),
    fallbackLng: 'ro',
    ns: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ro,
      ru,
      en,
    },
  });

  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider context={helmetContext}>
          <Global styles={global} />
          <ColorModeScript type="cookie" initialColorMode="system" storageKey="news24md" />
          <ChakraProvider theme={theme} colorModeManager={manager} resetCSS>
            <Router location={location} context={routerContext}>
              <ScrollToTop />
              {renderRoutes(routes)}
            </Router>
          </ChakraProvider>
        </HelmetProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
}

export default App;
