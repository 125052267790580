import i18n from 'i18next';

export const getFilters = (category: string) => {
  return [
    {
      exact: true,
      name: i18n.t('main.filters.top'),
      to: category ? `/${i18n.language}/${category}` : `/${i18n.language}/`,
    },
    {
      exact: true,
      name: i18n.t('main.filters.new'),
      to: category ? `/${i18n.language}/${category}/new` : `/${i18n.language}/new`,
    },
  ];
};
