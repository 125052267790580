import i18n from 'i18next';

export const getCategories = (mode: string) => {
  return [
    {
      name: i18n.t('main.categories.politics'),
      to: mode ? `/${i18n.language}/politics/${mode}` : `/${i18n.language}/politics`,
    },
    {
      name: i18n.t('main.categories.economy'),
      to: mode ? `/${i18n.language}/economy/${mode}` : `/${i18n.language}/economy`,
    },
    {
      name: i18n.t('main.categories.society'),
      to: mode ? `/${i18n.language}/society/${mode}` : `/${i18n.language}/society`,
    },
    {
      name: i18n.t('main.categories.world'),
      to: mode ? `/${i18n.language}/world/${mode}` : `/${i18n.language}/world`,
    },
    {
      name: i18n.t('main.categories.media'),
      to: mode ? `/${i18n.language}/media/${mode}` : `/${i18n.language}/media`,
    },
    {
      name: i18n.t('main.categories.sport'),
      to: mode ? `/${i18n.language}/sport/${mode}` : `/${i18n.language}/sport`,
    },
    {
      name: i18n.t('main.categories.auto'),
      to: mode ? `/${i18n.language}/auto/${mode}` : `/${i18n.language}/auto`,
    },
    {
      name: i18n.t('main.categories.others'),
      to: mode ? `/${i18n.language}/others/${mode}` : `/${i18n.language}/others`,
    },
  ];
};
