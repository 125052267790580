import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { hydrate as emotionHydrate } from '@emotion/css/macro';

// import reportWebVitals from './reportWebVitals';
import App from './App';

// @ts-ignore
const ids = window.__IDS__ || {};
// @ts-ignore
const location = window.__LOC__ || window.location.pathname;
// @ts-ignore
const preloadedApolloState = window.__ASTATE__ || {};

// @ts-ignore
delete window.__IDS__;
// @ts-ignore
delete window.__LOC__;
// @ts-ignore
delete window.__ASTATE__;

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_CLIENT_LINK as string,
});

const wssLink = new WebSocketLink({
  uri: process.env.REACT_APP_CLIENT_WSS as string,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wssLink,
  httpLink
);

const client = new ApolloClient({
  ssrForceFetchDelay: 300,
  cache: new InMemoryCache().restore(preloadedApolloState),
  link: splitLink,
});

const root = document.querySelector('#root');

if (root && root.hasChildNodes()) {
  loadableReady()
    .then(() => emotionHydrate(ids))
    // @ts-ignore
    // .then(() => hydrateRoot(root, <React.StrictMode children={<App {...props} />} />));
    .then(() => hydrateRoot(root, <App location={location} client={client} />));
} else {
  // @ts-ignore
  // .then(() => createRoot(root).render(<React.StrictMode children={<App {...props} />} />))
  createRoot(root).render(<App location={location} client={client} />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.warn);
