import { extendTheme } from '@chakra-ui/react';

import breakpoints from './breakpoints';
import components from './components';
import config from './config';
import colors from './colors';
import fonts from './fonts';

const theme = extendTheme({
  styles: {
    global: {
      html: { scrollBehavior: 'smooth' },
      body: {
        background: 'var(--colors-background)',
        height: '100vh',
      },
    },
  },
  breakpoints,
  components,
  config,
  colors,
  fonts,
});

export default theme;
