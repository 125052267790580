export const REGEX_TITLE_COUNTER = /^\([0-9]+\)/gu;

export const FILTER_MODE_NEW = 'new';
export const FILTER_MODE_DAY = 'day';
export const FILTER_MODE_WEEK = 'week';
export const FILTER_MODE_MONTH = 'month';
export const FILTER_MODE_YEAR = 'year';

export const CATEGORY_POLITICS = 'politics';
export const CATEGORY_ECONOMY = 'economy';
export const CATEGORY_SOCIETY = 'society';
export const CATEGORY_WORLD = 'world';
export const CATEGORY_MEDIA = 'media';
export const CATEGORY_SPORT = 'sport';
export const CATEGORY_AUTO = 'auto';
export const CATEGORY_OTHERS = 'others';

export const ONE_DAY_MS = 86400000;
export const ONE_MIN_MS = 60000;
