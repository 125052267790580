import loadable from '@loadable/component';

import { routerCategories, routerFilters } from '../utils';

export const routes = [
  {
    path: `/iframe`,
    component: loadable((): any => import('../pages/Iframe')),
    exact: true,
  },
  {
    component: loadable((): any => import('../pages/Root')),
    routes: [
      {
        path: [
          `/:lang/`,
          `/:lang/:mode(${routerFilters})`,
          `/:lang/:category(${routerCategories})`,
          `/:lang/:category(${routerCategories})/:mode(${routerFilters})`,
        ],
        component: loadable((): any => import('../pages/Home')),
        exact: true,
      },
      {
        path: `/:lang/stories`,
        component: loadable(() => import('../pages/Stories')),
        routes: [
          {
            path: `/:lang/stories`,
            component: loadable(() => import('../pages/Stories')),
            exact: true,
          },
          {
            path: `/:lang/stories/:slug-:hash`,
            component: loadable(() => import('../pages/Story')),
            exact: true,
          },
        ],
      },
      {
        path: `/:lang/about/`,
        component: loadable((): any => import('../pages/About')),
        exact: true,
      },
      {
        component: loadable(() => import('../pages/NotFound')),
      },
    ],
  },
];
