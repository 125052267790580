import { css } from '@emotion/react';

export const global = css`
  body[class='chakra-ui-light'] {
    --colors-logo: #283252;
    --colors-pulse: #e62965;
    --colors-switcher: #ffb62e;
    --colors-background: #f0f0f0;
    --colors-heading: #283252;
    --colors-menu: #ededed;
    --colors-menuBackground: #fff;
    --colors-button: #a2a5b9;
    --colors-buttonBackground: #fff;
    --colors-buttonShareBackground: #f2f2f2;
    --colors-menuHeader: #a2a5b9;
    --colors-menuDivider: #eee;
    --colors-menuTitle: #283252;
    --colors-menuDate: #bdbfcd;
    --colors-filterColor: #283252;
    --colors-filterBackgroundGroup: #fff;
    --colors-filterBackgroundSmall: #f0f0f0;
    --colors-filterBackgroundLarge: #fff;
    --colors-cardShadow: #e2e2e5;
    --colors-cardBackground: #fff;
    --colors-cardTitleColor: #283252;
    --colors-cardColor: #656773;
    --colors-widgetBorder: #dedede;
    --colors-widgetTitle: #283252;
    --colors-widgetText: #656773;
    --colors-widgetTextHover: #283252;
    --colors-widgetTextActive: #41b983;
    --colors-breadcrumb: #656773;
    --colors-breadcrumbHover: #283252;
    --colors-breadcrumbActive: #283252;
    --colors-feedUpBackground: #fff;
    --colors-feedUpColor: #283252;
  }

  body[class='chakra-ui-dark'] {
    --colors-logo: #aaaab3;
    --colors-pulse: #e62965;
    --colors-switcher: #ffb62e;
    --colors-background: #3b3b41;
    --colors-heading: #aaaab3;
    --colors-menu: #37373b;
    --colors-menuBackground: #232326;
    --colors-button: #a2a5b9;
    --colors-buttonBackground: #28282b;
    --colors-buttonShareBackground: #28282b;
    --colors-menuHeader: #a2a5b9;
    --colors-menuDivider: #37373b;
    --colors-menuTitle: #aaaab3;
    --colors-menuDate: #7b7d8a;
    --colors-filterColor: #aaaab3;
    --colors-filterBackgroundGroup: #323236;
    --colors-filterBackgroundSmall: #3b3b41;
    --colors-filterBackgroundLarge: #323236;
    --colors-cardShadow: #656773;
    --colors-cardBackground: #323236;
    --colors-cardTitleColor: #aaaab3;
    --colors-cardColor: #a2a5b9;
    --colors-widgetBorder: #595960;
    --colors-widgetTitle: #aaaab3;
    --colors-widgetText: #a2a5b9;
    --colors-widgetTextHover: #fff;
    --colors-widgetTextActive: #41b983;
    --colors-breadcrumb: #aaaab3;
    --colors-breadcrumbHover: #fff;
    --colors-breadcrumbActive: #fff;
    --colors-feedUpBackground: #323236;
    --colors-feedUpColor: #aaaab3;
  }
`;
