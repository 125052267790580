import {
  CATEGORY_AUTO,
  CATEGORY_ECONOMY,
  CATEGORY_MEDIA,
  CATEGORY_OTHERS,
  CATEGORY_POLITICS,
  CATEGORY_SOCIETY,
  CATEGORY_SPORT,
  CATEGORY_WORLD,
  FILTER_MODE_DAY,
  FILTER_MODE_MONTH,
  FILTER_MODE_NEW,
  FILTER_MODE_WEEK,
  FILTER_MODE_YEAR,
} from './constants';

export const routerCategories = [
  CATEGORY_POLITICS,
  CATEGORY_ECONOMY,
  CATEGORY_SOCIETY,
  CATEGORY_WORLD,
  CATEGORY_MEDIA,
  CATEGORY_SPORT,
  CATEGORY_AUTO,
  CATEGORY_OTHERS,
].join('|');

export const routerFilters = [
  FILTER_MODE_NEW,
  FILTER_MODE_DAY,
  FILTER_MODE_WEEK,
  FILTER_MODE_MONTH,
  FILTER_MODE_YEAR,
].join('|');

export * from './constants';
export * from './canUseDom';
export * from './categories';
export * from './filters';
export * from './colors';
export * from './images';
export * from './dayjs';
export * from './meta';
