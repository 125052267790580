import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import i18n from 'i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

import { ONE_DAY_MS } from './constants';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export function isDateLessThan(createdAt: string | number, period: string | number) {
  const createdAtValue = typeof createdAt === 'string' ? parseInt(createdAt) : createdAt;
  const periodValue = typeof period === 'string' ? parseInt(period) : period;

  return dayjs().valueOf() - createdAtValue < periodValue;
}

export function getDate(createdAt: string | number): string {
  const createdAtValue = typeof createdAt === 'string' ? parseInt(createdAt) : createdAt;

  return dayjs().valueOf() - createdAtValue > ONE_DAY_MS
    ? dayjs(createdAt).locale(i18n.language).format('LL, LT')
    : dayjs(createdAt).locale(i18n.language).fromNow();
}

export default dayjs;
