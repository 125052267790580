import dayjs from 'dayjs';

// TODO: refactor needed!
export const getImg = (image: any, view: any, size = '255x145') => {
  let result;

  if (image) {
    const base = process.env.REACT_APP_CLIENT_IMG;
    const ymd = dayjs(view.created_at).format('YYYY/MM/DD');
    result = base + '/images/' + ymd + '/' + view.hash + '/' + size + '/' + image;
  }

  return result;
};
